import React from "react";

export default function ListGroupItem({
  title,
  additionalInfo,
  leftBtn,
  rightBtn,
  onClickTitle,
  activeTitleHover,
}: any) {
  return (
    <li className="list-group-item justify-content-between">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 my-2">
          <div
            className={`font-semibold ${
              activeTitleHover && "in-hover-to-pointer"
            }`}
            onClick={onClickTitle && onClickTitle}
          >
            {title}
          </div>
          {additionalInfo && (
            <div className="font-semibold text-body-tertiary">
              {additionalInfo}
            </div>
          )}
        </div>
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-12 my-2"
          //   style={{ minWidth: "20%" }}
        >
          {/* {leftBtn}
          {rightBtn} */}
          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            {leftBtn}
            {rightBtn}
          </div>
        </div>
      </div>
    </li>
  );
}

export interface TestItem {
  id: number;
  fullname: string;
  shortname: string;
  additionalInfo?: string;
  yesBtnName: string;
  noBtnName: string;

  isSelected: boolean;
  yesScore: number;
  noScore: number;
}

export const TestItemList: TestItem[] = [
  {
    id: 1,
    fullname: "연령",
    shortname: "연령",
    yesBtnName: "80세 이상",
    noBtnName: "80세 미만",
    isSelected: false,
    yesScore: 16,
    noScore: 0,
  },
  {
    id: 2,
    fullname: "치매 여부 (진단명: ICD-10 Dementia 포함)",
    shortname: "치매여부",
    yesBtnName: "있음",
    noBtnName: "없음",
    isSelected: false,
    yesScore: 38,
    noScore: 0,
  },
  {
    id: 3,
    fullname: "마비 증상 (진단명: ICD-10 Hemiplegia, Paraplegia 포함)",
    shortname: "마비증상",
    yesBtnName: "있음",
    noBtnName: "없음",
    isSelected: false,
    yesScore: 10,
    noScore: 0,
  },
  {
    id: 4,
    fullname: "카테터 삽입 (EMR 기록 모든 카테터)",
    shortname: "카테터삽입",
    yesBtnName: "있음",
    noBtnName: "없음",
    isSelected: false,
    yesScore: 10,
    noScore: 0,
  },
  {
    id: 5,
    fullname: "칼륨 수치 (입원 후 24시간 이내 K 수치)",
    shortname: "칼륨",
    additionalInfo: "비정상 기준: ≤ 3.5 or > 4.8. mmol/i",
    yesBtnName: "비정상",
    noBtnName: "정상",
    isSelected: false,
    yesScore: 3,
    noScore: 0,
  },
  {
    id: 6,
    fullname: "크레아티닌 수치 (입원 후 24시간 이내 Cr 수치)",
    shortname: "크레아티닌",
    additionalInfo: "비정상 기준: ≥ 133 umol/l",
    yesBtnName: "비정상",
    noBtnName: "정상",
    isSelected: false,
    yesScore: 1,
    noScore: 0,
  },
  {
    id: 7,
    fullname: "약물 다중 복용 (EMR 투약 기준)",
    shortname: "약물다중복용",
    yesBtnName: "6개 이상",
    noBtnName: "없음",
    isSelected: false,
    yesScore: 2,
    noScore: 0,
  },
  {
    id: 8,
    fullname: "CrAs를 통한 항콜린 약물부담 (CrAs 목록 약물 채점)",
    shortname: "CrAs약물",
    yesBtnName: "3 이상",
    noBtnName: "3 미만",
    isSelected: false,
    yesScore: 12,
    noScore: 0,
  },
  {
    id: 9,
    fullname: "진료과 (EMR 확인)",
    shortname: "진료과",
    yesBtnName: "내과",
    noBtnName: "외과",
    isSelected: false,
    yesScore: 8,
    noScore: 0,
  },
];

import React from "react";

export default function InputGroup({
  title,
  value,
  required,
  updateValue,
  inputType,
}: any) {
  const regex = /[^0-9]/g;

  const handleChange = (e: any) => {
    let value = e.target.value;

    if (inputType && inputType === "number") {
      value = value.replace(regex, "");
      console.log(value);
    }

    updateValue(value);
  };

  return (
    <div className="row mb-3 ">
      <label className="text-start col-sm-3  col-form-label fw-semibold fs-5">
        {title}
      </label>
      <div className="col-sm-9">
        <input
          type="text"
          className="form-control"
          value={value}
          required={required}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

//   <div className="input-group  mb-3">
//     <span
//       className="input-group-text"
//       style={{ display: "block", width: "6rem", textAlign: "center" }}
//     >
//       {title}
//     </span>
//     <input
//       type="text"
//       className="form-control"
//       placeholder="검사자를 입력하세요"
//       value={value}
//       required={required}
//     />
//     {/* <div className="invalid-feedback"></div> */}
//   </div>;

import { WARNING_LEVEL, WARNING_LEVEL_TYPE } from "constants/BaseContstant";
import { TestItemList } from "constants/TestItem";
import { useEffect, useState } from "react";
import { DeliriumTestDto, TestResult } from "types/DeliriumTestType";

const initTest = {
  tester: "",
  patientName: "",
  registrationNumber: "",
  testItems: TestItemList,
  totalScore: 0,
  warningLevel: WARNING_LEVEL.LOW,
};

export function useDeliriumTest() {
  const [deliriumTest, setDeliriumTest] = useState<DeliriumTestDto>(initTest);

  const clearTest = () => {
    setDeliriumTest(initTest);
  };

  // tester 필드를 업데이트하는 함수
  const updateTester = (newTester: string) => {
    setDeliriumTest((prevState) => ({
      ...prevState,
      tester: newTester,
    }));
  };

  // patientName 필드를 업데이트하는 함수
  const updatePatientName = (newPatientName: string) => {
    setDeliriumTest((prevState) => ({
      ...prevState,
      patientName: newPatientName,
    }));
  };

  // registrationNumber 필드를 업데이트하는 함수
  const updateRegistrationNumber = (newRegistrationNumber: string) => {
    setDeliriumTest((prevState) => ({
      ...prevState,
      registrationNumber: newRegistrationNumber,
    }));
  };

  // 테스트 항목의 선택 여부를 업데이트하는 함수
  const updateTestItemSelection = (itemId: number, isSelected: boolean) => {
    setDeliriumTest((prevState) => ({
      ...prevState,
      testItems: prevState.testItems.map((item) =>
        item.id === itemId ? { ...item, isSelected } : item
      ),
    }));
  };

  const updateTestItemSelectionBasedOnScore = (
    itemId: number,
    score: number,
    baseScore: number
  ) => {
    setDeliriumTest((prevState) => ({
      ...prevState,
      testItems: prevState.testItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, isSelected: score >= baseScore };
        }
        return item;
      }),
    }));
  };

  // 테스트의 총 점수를 계산하는 함수
  const calculateTotalScore = () => {
    const totalScore = deliriumTest.testItems.reduce(
      (sum, item) =>
        item.isSelected ? sum + item.yesScore : sum + item.noScore,
      0
    );

    let newWarningLevel: WARNING_LEVEL_TYPE;

    if (totalScore >= 64) {
      newWarningLevel = WARNING_LEVEL.HIGH;
    } else if (totalScore >= 41) {
      newWarningLevel = WARNING_LEVEL.MID;
    } else {
      newWarningLevel = WARNING_LEVEL.LOW;
    }
    setDeliriumTest((prevState) => ({
      ...prevState,
      totalScore,
      warningLevel: newWarningLevel,
    }));
  };

  const convertToDeliriumPostDto = () => {
    const itemScores = deliriumTest.testItems.map((item) => {
      // TestItem을 TestResult로 변환
      const testResult: TestResult = {
        id: item.id,
        name: item.shortname,
        value: item.isSelected ? item.yesBtnName : item.noBtnName,
        score: item.isSelected ? item.yesScore : item.noScore,
      };

      return testResult;
    });

    // DeliriumPostDto 객체 생성
    return {
      tester: deliriumTest.tester,
      patientName: deliriumTest.patientName,
      registrationNumber: deliriumTest.registrationNumber,
      itemScores: itemScores,
      totalScore: deliriumTest.totalScore,
      warningLevel: deliriumTest.warningLevel,
    };
  };

  // 테스트 항목이 업데이트될 때마다 총 점수를 재계산합니다.
  useEffect(() => {
    calculateTotalScore();
  }, [deliriumTest.testItems]);

  // Hook에서 관리되는 상태와 함수들을 반환합니다.
  return {
    deliriumTest,
    clearTest,
    convertToDeliriumPostDto,
    updateTester,
    updatePatientName,
    updateRegistrationNumber,
    updateTestItemSelection,
    updateTestItemSelectionBasedOnScore,
  };
}

import { CrAsDrugList } from "constants/CrAsDrugList";
import { useCrAsList } from "hooks/useCrAsList";
import React from "react";
import { CrAsDrug } from "types/DeliriumTestType";

const CrAsScoreList = ({ drugs, toggleDrugSelection }: any) => {
  // const { drugs, toggleDrugSelection, totalScore } = useCrAsList();

  return (
    <div className="py-1">
      {[3, 2, 1].map((score) => (
        <div className="" key={score}>
          <h6 className="ml-1 h5 font-bold">점수 {score}</h6>

          <div className="">
            <table className=" table table-bordered table-hover my-1 min-w-64">
              <thead className="table-light ">
                <tr className="font-bold">
                  <th className="py-0">
                    <small>약물 성분</small>
                  </th>
                  <th className="py-0">
                    <small>처방명</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {drugs
                  .filter((drug: CrAsDrug) => drug.score === score)
                  .map((drug: CrAsDrug, index: number) => (
                    <tr
                      key={index}
                      className={`w-100 ${
                        drug.isSelected ? "table-primary" : ""
                      }`}
                      onClick={() => toggleDrugSelection(drug.id)}
                    >
                      <td className="py-0 min-w-32 w-32">
                        <small>{drug.drugIngredient}</small>
                      </td>
                      <td className="py-0 max-w-0 ">
                        <small>{drug.prescriptionName}</small>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CrAsScoreList;

import { CrAsDrug } from "types/DeliriumTestType";
import { useState, useCallback } from "react";
import { CrAsDrugList } from "constants/CrAsDrugList";

export const useCrAsList = () => {
  const [drugs, setDrugs] = useState<CrAsDrug[]>(CrAsDrugList);

  // Function to toggle the isSelected property
  const toggleDrugSelection = useCallback((id: number) => {
    setDrugs((currentDrugs) =>
      currentDrugs.map((drug) =>
        drug.id === id ? { ...drug, isSelected: !drug.isSelected } : drug
      )
    );
  }, []);

  // Calculate the total score of selected drugs
  const totalScore = drugs.reduce((total, drug) => {
    return drug.isSelected ? total + drug.score : total;
  }, 0);

  return { drugs, toggleDrugSelection, totalScore };
};

import { ApiResponseDto } from "types/BaseType";
import { postData } from "./BaseApi";
const server_host = process.env.REACT_APP_SERVER_HOST;

const DeliriumAssessmentApi = {
  postDeliriumAssessment: async (data: any): Promise<ApiResponseDto> => {
    const response: ApiResponseDto = await postData(
      "/api/v1/delirium-assessment",
      null,
      data
    );
    return response;
  },

  getpostDeliriumAssessmentByPassword: async (password: string) => {
    let headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${server_host}/api/v1/delirium-assessment/excel`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ password: password }),
        }
      );

      if (response.ok) {
        const contentType = response.headers.get("Content-Type");

        if (contentType === "application/json") {
          const data = await response.json(); // async/await 사용
          throw new Error(data.message); // 여기서 발생한 오류는 아래 catch 블록으로 전달됩니다.
        } else {
          let filename = "섬망선별_결과목록.xlsx";
          // Process the response as a Blob
          const blob: Blob = await response.blob();
          const url: string = window.URL.createObjectURL(blob);

          // Create an anchor element and download the file
          const a: HTMLAnchorElement = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      } else {
        throw new Error("서버에서 예기치 못한 오류가 발생했습니다.");
      }
    } catch (error) {
      throw error;
    }
  },
};

export default DeliriumAssessmentApi;

import React from "react";
import OffCanvas from "./OffCanvas";
import CrAsScoreList from "./CrAsScoreList";
import { useCrAsList } from "hooks/useCrAsList";

export default function CrAsOffCanvas({
  show,
  closeCanvas,
  applyCrAsScore,
  itemId,
}: any) {
  const { drugs, toggleDrugSelection, totalScore } = useCrAsList();

  return (
    <div>
      <OffCanvas
        title="CrAs 약물목록 및 처방명 "
        body={
          <CrAsScoreList
            drugs={drugs}
            toggleDrugSelection={toggleDrugSelection}
          />
        }
        footer={
          <div className="">
            <h4 className="h5 fw-bold warning-level d-flex justify-content-start align-items-center">
              <span
                style={{
                  minWidth:
                    totalScore < 10
                      ? "4rem"
                      : totalScore <= 99
                      ? "4.6rem"
                      : "5.5rem",
                }}
              >{`총점 ${totalScore} `}</span>
              <span
                className={`badge ${
                  totalScore >= 3 ? "bg-danger" : "bg-success"
                }`}
              >
                {totalScore >= 3 ? "3 이상" : "3 미만"}
              </span>

              <div className="d-flex justify-content-end w-100">
                <button
                  className={`btn btn-auto btn-primary  bt-blue`}
                  onClick={() => {
                    applyCrAsScore(itemId, totalScore, 3);
                    closeCanvas();
                  }}
                >
                  적용
                </button>
              </div>
            </h4>
          </div>
        }
        show={show}
        closeCanvas={closeCanvas}
      />
    </div>
  );
}

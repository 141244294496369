import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { ModalProvider } from "contexts/ModalContext";
import AlertModal from "components/AlertModal";

export default function Main() {
  return (
    <ModalProvider>
      <div
        className="d-flex justify-content-center align-items-center bg-light-blue"
        style={{
          minHeight: "100vh",
          minWidth: "100vW",
          maxWidth: "800px",
          height: "100%",

          width: "100%",
        }}
      >
        <AlertModal />

        <Outlet />
      </div>
    </ModalProvider>
  );
}

import DeliriumPredictionPage from "pages/DeliriumPredictionPage";
import DeliriumResultPage from "pages/DeliriumResultPage";
import Main from "pages/Main";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<DeliriumPredictionPage />} />
          <Route path="/regist" element={<DeliriumPredictionPage />} />
          <Route path="/result" element={<DeliriumResultPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

import React, { useContext, useState } from "react";
import Navbar from "./Navbar";
import DeliriumAssessmentApi from "apis/DeliriumAssessmentApi";
import { ModalContext } from "contexts/ModalContext";

export default function DeliriumResultPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>("");
  const modalContext = useContext(ModalContext);
  const handleSubmit = () => {
    DeliriumAssessmentApi.getpostDeliriumAssessmentByPassword(password).catch(
      (e) => {
        modalContext.openModal("오류 메시지", e.message);
      }
    );
  };

  return (
    <div
      className="container  content text-center delirium-result-form"
      style={{ background: "white" }}
    >
      <div className="top">
        <div className="row ">
          <h3 className="h2 font-bold">섬망 결과 조회</h3>
        </div>
        <div className="row">
          <h6 className="h6 ">패스워드를 입력해 주세요.</h6>
        </div>
      </div>

      <div className="w-100 my-2" />

      <div className="input-group needs-validation was-validated">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {/* <button> */}
        <span className="input-group-text">
          <button onClick={() => setShowPassword((pre) => !pre)}>
            <span className="material-icons-round">
              {showPassword ? "lock_outline" : "lock_open"}
            </span>
          </button>
        </span>
        {/* </button> */}
      </div>

      <div className="w-100 my-2" />

      <button
        className={`btn btn-primary  bt-blue  font-bold `}
        style={{ width: "100%" }}
        onClick={handleSubmit}
      >
        결과 다운로드
      </button>
    </div>
  );
}

import useOutsideClick from "hooks/useOutsideClick";
import React, { useRef } from "react";

export default function OffCanvas({
  title,
  body,
  footer,
  show,
  closeCanvas,
}: any) {
  const divRef = useRef<HTMLDivElement>(null);
  useOutsideClick<HTMLDivElement>(divRef, () => show && closeCanvas());

  return (
    <>
      <div
        className={`offcanvas offcanvas-start ${show && "show"}`}
        // style={{ width: "100vw", maxWidth: "300px" }}
        data-bs-scroll="true"
        tabIndex={-1}
        ref={divRef}
      >
        <div className="offcanvas-header bg-light bg-opacity-75 py-2">
          <h5 className="h5 offcanvas-title fw-semibold ">{title}</h5>
          <button type="button" className="btn-close" onClick={closeCanvas}>
            <span className="material-icons-round">close</span>
          </button>
        </div>
        <div className="offcanvas-body p-0">{body}</div>
        <div className="offcanvas-footer bg-light bg-opacity-75 px-2 py-2">
          {footer}
        </div>
      </div>
      <div className={`  ${show && "offcanvas-backdrop fade show"}`} />
    </>
  );
}

export const WARNING_LEVEL = {
  HIGH: "HIGH",
  MID: "MID",
  LOW: "LOW",
} as const;
export type WARNING_LEVEL_TYPE =
  (typeof WARNING_LEVEL)[keyof typeof WARNING_LEVEL];

export const DISPLAY_WARNING_LEVEL: { [key in WARNING_LEVEL_TYPE]: string } = {
  HIGH: "고위험",
  MID: "주의관찰",
  LOW: "저위험",
};

import CrAsScoreList from "components/CrAsScoreList";
import HorizonLine from "components/HorizonLine";
import OffCanvas from "components/OffCanvas";
import React, { useEffect, useState } from "react";

export default function Navbar() {
  // const [isOver600, setIsOver600] = useState(false);
  // const [isOver375, setIsOver375] = useState(false);
  // useEffect(() => {
  //   const handleResize = () => {
  //     const currentWidth = window.innerWidth;
  //     if (currentWidth > 600) {
  //       setIsOver600(true);
  //     } else {
  //       setIsOver600(false);
  //     }

  //     if (currentWidth > 375) {
  //       setIsOver375(true);
  //     } else {
  //       setIsOver375(false);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <header className="navbar navbar-expand-lg bd-navbar mt-2">
      <nav className="container-fluid bd-gutter flex-wrap flex-lg-nowrap">
        <div className="w-100">
          <div className="row">
            <h3 className="h4 font-bold">디지털 섬망 선별 도구</h3>
            {/* <div className="col-span-2 sm:col-span-1"></div> */}

            {/* <div className="items-center justify-center hidden col-span-1 space-x-2 sm:flex"></div>

            <div className="flex justify-end col-span-1">
              <div
                className="d-flex justify-content-end align-items-center"

                // style={{ width: "100%" }}
              >
                {isOver600 ? (
                  <img
                    src="/images/digital_medic_logo.png"
                    className=""
                    style={{ height: "2rem" }}
                  />
                ) : (
                  <img
                    src="/images/digital_medic_only_logo.png"
                    className=""
                    style={{ height: `${isOver375 ? "2rem" : "1.5rem"}` }}
                  />
                )}

                <div
                  className="d-flex justify-content-end align-items-center"
                  onClick={clickPartnerOrganizationInfo}
                  // style={{ }}
                >
                  <span className="ml-2 material-icons-round">
                    info_outline
                  </span>
                </div>
              </div>
            </div> */}
          </div>
          <HorizonLine />
        </div>
      </nav>
    </header>
  );
}

//  <div className="grid grid-cols-3">
//           <div className="col-span-2 sm:col-span-1">
//             <h3 className="h3 font-bold">섬망 예측 도구</h3>
//           </div>

//           <div className="items-center justify-center hidden col-span-1 space-x-2 sm:flex"></div>

//           <div className="flex justify-end col-span-1">
//             <div
//               className="d-flex justify-content-end align-items-center"
//               // style={{ width: "100%" }}
//             >
//               {isOverMaxWidth ? (
//                 <img
//                   src="/images/digital_medic_logo.png"
//                   className=""
//                   style={{ height: "2rem" }}
//                 />
//               ) : (
//                 <img
//                   src="/images/digital_medic_only_logo.png"
//                   className=""
//                   style={{ height: "2rem" }}
//                 />
//               )}

//               <button
//                 type="button"
//                 className="ml-2 btn btn-primary bt-blue"
//                 // style={{ height: "2rem" }}
//               >
//                 협력 기관
//               </button>
//             </div>
//           </div>
//         </div>
//         <HorizonLine />

import React, { useState, createContext } from "react";

const Modal = {
  isModalOpen: false,
  modalTitle: "알림",
  modalContent: "",
  openModal: (title?: string, content?: string) => {},
  closeModal: () => {},
};

const ModalContext = createContext(Modal);

const ModalProvider = ({ children }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("알림");
  const [modalContent, setModalContent] = useState("");

  const openModal = (title?: string, content?: string) => {
    title && setModalTitle(title);
    content && setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{ isModalOpen, modalTitle, modalContent, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };

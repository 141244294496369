import { useContext, useEffect } from "react";

import styles from "../Modal.module.css";
import { ModalContext } from "contexts/ModalContext";
const AlertModal = () => {
  const modalContext = useContext(ModalContext);

  return (
    <>
      {modalContext.isModalOpen === true && (
        <div
          className="modal fade show"
          role="dialog"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ borderRadius: 0 }}>
              <div className="modal-header" style={{ borderBottom: 0 }}>
                <h5 className="modal-title h5 fw-bold">
                  {modalContext.modalTitle}
                </h5>
                <button
                  className="btn-close"
                  onClick={modalContext.closeModal}
                ></button>
              </div>

              <div className="modal-body">
                <div className="label-main ">{modalContext.modalContent}</div>
                <div className="label-options"></div>
              </div>

              <div className="modal-footer bg-depth-3 ">
                <button
                  type="button"
                  className="btn btn-primary bt-blue"
                  onClick={modalContext.closeModal}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModal;

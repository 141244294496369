import { CrAsDrug } from "types/DeliriumTestType";

export const CrAsDrugList: CrAsDrug[] = [
  // Score 3 drugs
  {
    id: 1,
    drugIngredient: "Amitriptyline",
    prescriptionName: "Enafon/Etravil",
    score: 3,
    isSelected: false,
  },
  {
    id: 2,
    drugIngredient: "Atropine",
    prescriptionName: "Atropine",
    score: 3,
    isSelected: false,
  },
  {
    id: 4,
    drugIngredient: "Chlorpheniramine",
    prescriptionName: "Peniramin",
    score: 3,
    isSelected: false,
  },
  {
    id: 5,
    drugIngredient: "Chlorpromazine",
    prescriptionName: "ChlorPROmazine",
    score: 3,
    isSelected: false,
  },

  {
    id: 7,
    drugIngredient: "Doxepin",
    prescriptionName: "Silenor",
    score: 3,
    isSelected: false,
  },
  {
    id: 8,
    drugIngredient: "Homatropine",
    prescriptionName: "Homapine안약",
    score: 3,
    isSelected: false,
  },
  {
    id: 9,
    drugIngredient: "Imipramine",
    prescriptionName: "Imipramine",
    score: 3,
    isSelected: false,
  },
  {
    id: 10,
    drugIngredient: "Nortriptyline",
    prescriptionName: "Sensival",
    score: 3,
    isSelected: false,
  },
  {
    id: 12,
    drugIngredient: "Scopolamine",
    prescriptionName: "Buscopan/Freepan",
    score: 3,
    isSelected: false,
  },
  {
    id: 15,
    drugIngredient: "Trihexyphenidyl",
    prescriptionName: "Trihexin",
    score: 3,
    isSelected: false,
  },

  // Score 2 drugs
  {
    id: 16,
    drugIngredient: "Baclofen",
    prescriptionName: "Baclofen",
    score: 2,
    isSelected: false,
  },
  {
    id: 19,
    drugIngredient: "Fexofenadine",
    prescriptionName: "Allegra",
    score: 2,
    isSelected: false,
  },
  {
    id: 22,
    drugIngredient: "Paroxetine",
    prescriptionName: "A-xat CR, Paxil CR, Seroxa",
    score: 2,
    isSelected: false,
  },
  {
    id: 27,
    drugIngredient: "Quetiapine",
    prescriptionName: "Quetiapine, Seroquel",
    score: 2,
    isSelected: false,
  },
  {
    id: 29,
    drugIngredient: "Tramadol",
    prescriptionName: "Synerjet ER, Tramarol(ER), Tridol, Ultracet(ER), Tridol",
    score: 2,
    isSelected: false,
  },

  // Score 1 drugs
  {
    id: 30,
    drugIngredient: "Alprazolam",
    prescriptionName: "Xanax",
    score: 1,
    isSelected: false,
  },
  {
    id: 31,
    drugIngredient: "Atenolol",
    prescriptionName: "Tenormin",
    score: 1,
    isSelected: false,
  },
  {
    id: 33,
    drugIngredient: "Betaxolol",
    prescriptionName: "Betoptic-S 안약",
    score: 1,
    isSelected: false,
  },
  {
    id: 34,
    drugIngredient: "Bupropion",
    prescriptionName: "Nicopion SR, Wellbutrin XL",
    score: 1,
    isSelected: false,
  },
  {
    id: 35,
    drugIngredient: "Carbamazepine",
    prescriptionName: "Carbamazepine(CR)",
    score: 1,
    isSelected: false,
  },
  {
    id: 37,
    drugIngredient: "Chlordiazepoxide",
    prescriptionName: "Liber TY, LibraX(향정)",
    score: 1,
    isSelected: false,
  },
  {
    id: 38,
    drugIngredient: "Codeine",
    prescriptionName: "Codeine phosphate(마약)",
    score: 1,
    isSelected: false,
  },
  {
    id: 39,
    drugIngredient: "Cyclobenzaprine",
    prescriptionName: "Cyclpen",
    score: 1,
    isSelected: false,
  },
  {
    id: 41,
    drugIngredient: "Diazepam",
    prescriptionName: "Diazepam",
    score: 1,
    isSelected: false,
  },
  {
    id: 42,
    drugIngredient: "Fluoxetine",
    prescriptionName: "Foxetin, Proctin dispersible",
    score: 1,
    isSelected: false,
  },
  {
    id: 44,
    drugIngredient: "Ketorolac",
    prescriptionName: "Keromin, Acuvail(안약)",
    score: 1,
    isSelected: false,
  },
  {
    id: 45,
    drugIngredient: "Loperamide",
    prescriptionName: "Loperamide",
    score: 1,
    isSelected: false,
  },
  {
    id: 46,
    drugIngredient: "Loratadine",
    prescriptionName: "Loratadin",
    score: 1,
    isSelected: false,
  },
  {
    id: 47,
    drugIngredient: "Methocarbamol",
    prescriptionName: "Methocarbamol",
    score: 1,
    isSelected: false,
  },
  {
    id: 49,
    drugIngredient: "Morphine",
    prescriptionName: "Highmol inj(PCA용), Morphine(염산몰핀, 황산몰핀)",
    score: 1,
    isSelected: false,
  },
  {
    id: 51,
    drugIngredient: "Olanzapine",
    prescriptionName: "Zyprexa(zydis), Zyrepine",
    score: 1,
    isSelected: false,
  },
  {
    id: 52,
    drugIngredient: "Oxycodone",
    prescriptionName: "IR Codon, Oxycontin CR, Targin CR, Oxynorm",
    score: 1,
    isSelected: false,
  },
  {
    id: 53,
    drugIngredient: "Phenobarbital",
    prescriptionName: "Phenobarbital(향정)",
    score: 1,
    isSelected: false,
  },
  {
    id: 54,
    drugIngredient: "Risperidone",
    prescriptionName: "Rispen Risperdal",
    score: 1,
    isSelected: false,
  },
  {
    id: 56,
    drugIngredient: "Sertraline",
    prescriptionName: "Sertraline, Zoloft, Zoloft",
    score: 1,
    isSelected: false,
  },
  {
    id: 58,
    drugIngredient: "Trazodone",
    prescriptionName: "Trittico",
    score: 1,
    isSelected: false,
  },
  {
    id: 59,
    drugIngredient: "Triazolam",
    prescriptionName: "Triram(향정)",
    score: 1,
    isSelected: false,
  },
  {
    id: 60,
    drugIngredient: "Venlafaxine",
    prescriptionName: "Efexor-XR",
    score: 1,
    isSelected: false,
  },
];

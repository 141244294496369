import { ApiResponseDto } from "types/BaseType";

const server_host = process.env.REACT_APP_SERVER_HOST;

const fetchData = async (
  path: string,
  method: string,
  customHeaders?: any,
  data?: any
): Promise<ApiResponseDto> => {
  let headers: any = {};
  method !== "GET" && (headers["Content-Type"] = "application/json");
  customHeaders && (headers = { ...headers, ...customHeaders });

  const response = await fetch(`${server_host}${path}`, {
    method: method,
    headers: headers,
    body: data && JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response.json();
    })
    .catch((error) => {
      console.log("error = ", error);
      handleError(error);
    });

  return response;
};

export const getData = async (
  path: string,
  headers?: any,
  params?: any
): Promise<ApiResponseDto> => {
  let url = `${path}`;
  params && (url += `?${new URLSearchParams(params)}`);
  return await fetchData(url, "GET", headers);
};

export const postData = async (
  path: string,
  headers?: any,
  data?: any
): Promise<ApiResponseDto> => {
  return await fetchData(path, "POST", headers, data);
};

export const patchData = async (path: string, headers?: any, data?: any) => {
  return await fetchData(path, "PATCH", headers, data);
};

export const deleteData = async (path: string) => {
  return await fetchData(path, "DELETE");
};

const handleError = (error: any) => {
  console.error(error);
};

import DeliriumAssessmentApi from "apis/DeliriumAssessmentApi";
import CrAsScoreList from "components/CrAsScoreList";
import Dot from "components/Dot";
import HorizonLine from "components/HorizonLine";
import InputGroup from "components/InputGroup";
import ListGroupItem from "components/ListGroupItem";
import OffCanvas from "components/OffCanvas";
import Loading from "components/loading/Loading";
import { DISPLAY_WARNING_LEVEL } from "constants/BaseContstant";
import { ModalContext } from "contexts/ModalContext";
import { useDeliriumTest } from "hooks/useDeliriumTest";
import React, { useState, useContext } from "react";
import Navbar from "./Navbar";
import CrAsOffCanvas from "components/CrAsOffCanvas";

export default function DeliriumPredictionPage() {
  const {
    deliriumTest,
    clearTest,
    convertToDeliriumPostDto,
    updateTester,
    updatePatientName,
    updateRegistrationNumber,
    updateTestItemSelection,
    updateTestItemSelectionBasedOnScore,
  } = useDeliriumTest();

  const modalContext = useContext(ModalContext);
  const [crAsInfoOpen, setCrAsInfoOpen] = useState(false);
  const [partnerOrganizationInfoIsOpen, setPartnerOrganizationInfoIsOpen] =
    useState(false);

  const handleSubmitBtnClick = () => {
    console.log(convertToDeliriumPostDto());
    DeliriumAssessmentApi.postDeliriumAssessment(
      convertToDeliriumPostDto()
    ).then((response) => {
      if (response.code === 0) {
        modalContext.openModal("안내 메시지", "등록이 완료되었습니다.");
        clearTest();
        scrollToTop();
      } else {
        modalContext.openModal("오류 메시지", response.message);
      }
    });
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div
      className="container-sm "
      style={{ background: "white", maxWidth: "800px" }}
    >
      <Navbar />

      <CrAsOffCanvas
        show={crAsInfoOpen}
        closeCanvas={() => setCrAsInfoOpen(false)}
        applyCrAsScore={updateTestItemSelectionBasedOnScore}
        itemId={8}
      />

      <div
        className="row needs-validation was-validated ms-1"
        style={{ maxWidth: "30rem" }}
      >
        <InputGroup
          title="검사자"
          value={deliriumTest.tester}
          required={true}
          updateValue={updateTester}
        />
        <InputGroup
          title="환자 이름"
          value={deliriumTest.patientName}
          required={true}
          updateValue={updatePatientName}
        />
        <InputGroup
          title="등록 번호"
          value={deliriumTest.registrationNumber}
          inputType={"number"}
          required={true}
          updateValue={updateRegistrationNumber}
        />
      </div>

      <HorizonLine />

      <p className="ms-1 fw-bold mb-8">입원 후 24시간 이내 관찰 기준</p>

      <div className="row">
        <ul className="list-group list-group-flush">
          {deliriumTest.testItems.map((testItem) => (
            <ListGroupItem
              key={testItem.id}
              title={testItem.fullname}
              additionalInfo={testItem.additionalInfo}
              leftBtn={
                <button
                  className={`btn btn-auto mr-1 mb-1 ${
                    testItem.isSelected
                      ? "btn-primary bt-blue"
                      : "btn-secondary bt-gray"
                  }`}
                  onClick={() => updateTestItemSelection(testItem.id, true)}
                >
                  <span>{testItem.yesBtnName}</span>
                </button>
              }
              rightBtn={
                <button
                  className={`btn btn-auto mb-1 ${
                    !testItem.isSelected
                      ? "btn-primary bt-blue"
                      : "btn-secondary  bt-gray"
                  }`}
                  onClick={() => updateTestItemSelection(testItem.id, false)}
                >
                  <div>{testItem.noBtnName}</div>
                </button>
              }
              onClickTitle={() => testItem.id === 8 && setCrAsInfoOpen(true)}
              activeTitleHover={testItem.id === 8 ? true : false}
            />
          ))}
        </ul>
      </div>

      <HorizonLine />

      <div className="row">
        <h5 className="h5 ms-1 fw-bold warning-level d-flex justify-content-start align-items-center">
          <span
            style={{
              minWidth:
                deliriumTest.totalScore < 10
                  ? "4rem"
                  : deliriumTest.totalScore <= 99
                  ? "4.6rem"
                  : "5.5rem",
            }}
          >{`총점 ${deliriumTest.totalScore} `}</span>
          <span
            className={`badge  mr-1 ${
              deliriumTest.warningLevel === "HIGH"
                ? "bg-danger"
                : deliriumTest.warningLevel === "MID"
                ? "bg-warning"
                : "bg-success"
            }`}
          >
            {DISPLAY_WARNING_LEVEL[deliriumTest.warningLevel]}
          </span>
          <div className="h6 d-flex justify-content-end align-items-center w-100 mr-1">
            <div className="fw-semibold">
              <button
                className={`btn btn-auto btn-primary  bt-blue mr-1`}
                onClick={handleSubmitBtnClick}
              >
                결과 제출
              </button>
              <span className="text-hide-on-550 text-body-tertiary">
                (입력 내용 및 총점 저장)
              </span>
            </div>
          </div>
        </h5>
      </div>

      <div className="row my-4 fw-light text-gray">
        <div className="d-flex justify-content-center align-items-center">
          Copyright© 2023
          <span
            className="ml-1 material-icons-round in-hover-to-pointer"
            style={{ fontSize: "1.35rem" }}
            onClick={() => setPartnerOrganizationInfoIsOpen((pre) => !pre)}
          >
            info_outline
          </span>
        </div>
      </div>
      {partnerOrganizationInfoIsOpen && (
        <div>
          <div className="row my-4 fw-light ">
            <div className="">
              본 결과물은 한국보건산업진흥원에서 시행한 2023년 스마트병원
              선도모델 개발지원 사업을 통해 용인 세브란스 병원과 강남
              세브란스병원에서 협력하여 진행된 결과물임
            </div>
          </div>
          <div className="row mx-4 justify-content-between align-items-center responsive-column mb-4">
            <div className="col-4 partner-organization-logo my-2">
              <img
                src="images/yongin_severance_logo.png"
                alt="YONGIN SEVERANCE HOSPITAL. logo"
              />
            </div>
            <div className="col-4 partner-organization-logo my-2">
              <img
                src="images/gangnam_severance_logo.png"
                alt="GANGNAM SEVERANCE HOSPITAL. logo"
              />
            </div>
            <div className="col-4 partner-organization-logo my-2">
              <img
                src="images/digital_medic_logo.png"
                alt="DIGITAL MEDIC LOGO. logo"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
